<template>
    <div class="orders-details-tabs-select">
        <div
            v-click-outside="clickOutside"
            class="form-select-tab"
            :class="{ 'grey-border' : openListItem }"
        >
            <fieldset :class="openListItem ? 'form-select-tab__fieldset active' : 'form-select-tab__fieldset'">
                <div class="form-select-tab__input-field">
                    <component
                        :is="dataSelectedTab.selectedIcon.name"
                        class="form-input-tab__prepend-icon"
                    />
                    <input
                        class="form-select-tab__input"
                        :value="setValue"
                        type="text"
                        :name="name"
                        autocomplete="off"
                        :placeholder="name"
                        :disabled="disabled"
                        readonly
                        @click.self="openListItem = !openListItem"
                        @focus="$event.target.select()"
                        @input="onInputChanged($event)"
                    >
                    <span
                        v-if="dataSelectedTab.selectedCounter !== false"
                        class="form-select-tab__counter"
                    >
                        {{ dataSelectedTab.selectedCounter }}
                    </span>
                    <span
                        :class="{'active': openListItem}"
                        class="form-select-tab__arrow"
                        @click="openListItem = !openListItem"
                    >
                        <chevron-down />
                    </span>
                </div>
                <ul
                    v-if="openListItem"
                    class="form-select-tab__list"
                >
                    <li
                        v-for="(item, index) in filteredList"
                        :key="index"
                        class="form-select-tab__list-item"
                        :class="{'active-tab-link': $route.name === item.value}"
                        @click="selectItem(item.value, item.icon, item.counter)"
                    >
                        <span class="form-select-tab__list-item-icon">
                            <component :is="item.icon" />
                        </span>
                        <span class="form-select-tab__list-item-text">{{ item.text }}</span>
                        <span
                            v-if="item.counter !== false"
                            class="form-select-tab__counter"
                        >
                            {{ item.counter }}
                            <span
                                v-if="item.counterUndread > 0"
                                class="bubble--unread bubble--unread-small"
                            >
                                {{ item.counterUndread }}
                            </span>
                        </span>
                    </li>
                </ul>
            </fieldset>
        </div>
    </div>
</template>

<script>
import BookOpenVariant from 'mdi-vue/BookOpenVariant.vue';
import FileDocument from 'mdi-vue/FileDocument.vue';
// import ClickOutside from 'vue-click-outside'
import ChevronDown from 'mdi-vue/ChevronDown'
import List from '@/components/icons/List'
import PaperClip from '@/components/icons/PaperClip'
import Mail from '@/components/icons/Mail'
import Support from '@/components/icons/Support'

export default {
    name: 'FormSelectTab',
    components: {
        BookOpenVariantIcon: BookOpenVariant,
        FileDocumentIcon: FileDocument,
        ChevronDown,
        List,
        PaperClip,
        Mail,
        Support
    },
    // directives: {
    //     ClickOutside
    // },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {},
        valueSelect: { type: [String, Number] },
        items: { type: Array },
        escape: { type: Boolean },
        name: {
            required: true,
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openListItem: false,
            filteredItems: [],
            search: '',
            dataSelectedTab: {
                selectedIcon: {
                    name: List
                },
                selectedCounter: false
            }
        };
    },
    computed: {
        setValue() {
            const isExist = this.items.some((el) => el.value === this.search);
            if (isExist) {
                this.$emit('input', this.search);
                return this.items.find((element) => element.value === this.search).text;
            }
            if (this.escape) {
                return ''
            }
            return this.search;
        },
        filteredList() {
            if (this.search === this.value) {
                return this.items
            }
            return this.items.filter((item) => item.text.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    watch: {
        value(value) {
            if (!value) {
                this.search = ''
            } else {
                this.search = value
            }
        },
        $route(to) {
            this.search = to.name
            this.items.forEach((item) => {
                if (item.value === this.search) {
                    this.dataSelectedTab.selectedCounter = item.counter
                }
            })
        }
    },
    created() {
        this.setInitValue();
        this.search = this.$route.name
        this.items.forEach((item) => {
            if (item.value === this.search) {
                this.dataSelectedTab.selectedCounter = item.counter
            }
        })
    },
    methods: {
        selectItem(value, icon, counter) {
            this.dataSelectedTab = {
                selectedIcon: icon,
                selectedCounter: counter
            }
            this.$emit('input', value);
            this.search = value
            this.openListItem = false;
        },
        onInputChanged(event) {
            this.openListItem = true;
            this.search = event.target.value;
            if (this.search === '') {
                this.$emit('input', '');
            }
        },
        setInitValue() {
            this.search = this.valueSelect ? this.valueSelect : '';
        },
        clickOutside() {
            this.openListItem = false
        }
    }
};
</script>

<style lang="scss">
.form-select-tab {
    outline: none;
    box-shadow: inset 0px -1px 0 0 #e7ecf4;
    border-bottom: 2px solid #EFEFEF;
    max-height: 52px!important;
    width: 100%;

    &.success-border {
        border: $input-border-success;
    }
    &.grey-border {
        //border: $input-border;
    }
    &__icon {
        position: absolute;
        top: 10px;
        left: 12px;
    }

    &__fieldset {
        position: relative;
        border: none ;
        outline: none;
    }

    &__legend {
        display: table;
        color: #b3bbc6;
        caret-color: #b3bbc6;
        padding: 2px 5px 2px 5px;
        font-size: 80%;
        position: absolute;
        left: 6px;
        top: -8px;
        background-color: white;
        border: none;
    }

    &__input {
        box-sizing: border-box;
        display: inline-block;
        padding-left: 12px;
        width: 100%;
        cursor: pointer;
        border: none ;
        outline: none;
        -webkit-border-radius: $input-border-radius;
        -moz-border-radius: $input-border-radius;
        border-radius: $input-border-radius;
        font-family: $font-family-base;
        font-size: 16px;
        font-weight: 500;
        max-height: 48px;
        height: 48px;
    }
    &__input-field {
        display: flex;
        align-items: center;
        background-color: #fff;
        svg {
            margin-left: 25px;
            margin-right: 0;
        }
        .active {
            background-color: #fff;
            svg {
                transform: rotate(-180deg);
            }
        }
    }
    &__arrow{
        margin-right: 15px;
    }
    &__list {
        overflow-y: scroll;
        max-height: 270px;
        background-color: white;
        position: absolute;
        z-index: 10;
        top: 55px;
        right: 0;
        text-align: left;
        width: 50%;
        margin: 0;
        padding: 0;
        -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
        border-radius: 4px;
        @media screen and (max-width: 768px) {
            width: 75%;
        }
        .active-tab-link{
            background: rgba(17, 97, 224, 0.1);
        }
    }
    &__counter{
        position: relative;
        display: inline-flex;
        align-items: baseline;
        margin-left: 5px;
        padding: 7px 10px;
        font-size: 16px;
        font-weight: 600;
        color: #1161E0;
        background: rgba(17, 97, 224, 0.1);
        margin-right: 15px;
    }
    &__list-item {
        padding: 10px 15px;
        background-color: #fff;
        margin-bottom: 1px;
        cursor: pointer;
        font-size: 14px;
        align-items: center;
        display: flex;
        &:hover {
            background-color: lightgrey;
            //color: #FFF;
        }
    }
    &__list-item-icon {
        width: 30px;
    }
    &__list-item-text {
        width: 110px;
    }
    &__error {
        display: inline;
        width: auto;
        text-align: left;
        color: $error-color;
        caret-color: $error-color;
        font-size: 12px;
        min-height: 14px;
        position: absolute;
        left: 13px;
        bottom: -7px;
        background: white;
        padding: 0 3px;
    }
}

.orders-details-tabs-select{
    display: none;
    @media screen and (max-width: 992px) {
        display: flex;
    }
}
</style>
