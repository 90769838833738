<template lang="html">
    <main-layout>
        <template #content>
            <div class="heading order-details__heading">
                <div class="wrapper wrapper-header">
                    <breadcrumbs :steps="breadcrumbsSteps" />
                    <div id="wrapper">
                        <div class="orders-title">
                            <h1>
                                Order Details
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <section class="wrapper wrapper--xs">
                <div class="my-orders-details">
                    <loading-overlay v-if="dataLoading" />
                    <div v-else>
                        <order-card
                            :order="order"
                            :show-index="false"
                            :opened-card="true"
                            @updateOrder="getOrderData"
                        />

                        <!-- Order information Tabs -->
                        <div
                            ref="tabs"
                            class="orders-details-tabs"
                        >
                            <order-details-tabs
                                :items="getTabs"
                                @redirectToTab="redirectToTab"
                            />
                            <order-details-select-tabs
                                v-model="selectedTab.value"
                                :field.sync="selectedTab.value"
                                :value-select="selectedTab.value"
                                :items="getTabs"
                                name="Tabs"
                            />
                            <router-view
                                v-if="watchOrderid"
                                :key="$route.fullPath"
                                :order="order"
                                :sw_id="sw_id"
                                @fileUploaded="filesAddCounter += 1"
                                @deleteFile="filesAddCounter -= 1"
                                @actionAddMessage="messageLength += 1"
                                @markAsRead="markAsReadCounter('messages')"
                                @markAsReadMain="makeAsReadFiles('files_main')"
                                @markAsReadAdditional="markAsReadCounter('files_add')"
                                @supporMarkAsRead="markAsReadCounterTickets('tickets', $event)"
                            />
                        </div>

                    <!-- <no-results
                        v-if="!dataLoading && noResults"
                        class="pt-5"
                        type="order-details"
                    /> -->
                    </div>
                </div>
            </section>
        </template>
    </main-layout>
</template>

<script>
// import Api from '@/helpers/api/index.js';
import { eventBus } from '@/helpers/event-bus'
import { scroller } from 'vue-scrollto/src/scrollTo'
import filtersMixin from '@/mixins/filtersMixin.js'
// import { makeAsReadFiles } from '@/services/files/filesService';

import {
    mapWriterFields
} from '@/store/modules/writer';
// import {
//     forEach
// } from 'lodash'

// Components
import MainLayout from '@/layouts/MainLayout.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import OrderCard from '@/components/account/OrderCard/OrderCard.vue'
import OrderDetailsTabs from '@/components/account/orders/OrderDetailsTabs.vue'
import OrderDetailsSelectTabs from '@/components/account/orders/OrderDetailsSelectTabs.vue'
import Breadcrumbs from '@/components/Breadcrumbs';
// icons
import List from '@/components/icons/List'
import PaperClip from '@/components/icons/PaperClip'
import Mail from '@/components/icons/Mail'
import Support from '@/components/icons/Support'

import { fetchOrder } from '@/services/orders/orderService.js'

export default {
    name: 'OrderDetails',
    components: {
        MainLayout,
        Breadcrumbs,
        OrderCard,
        LoadingOverlay,
        OrderDetailsTabs,
        OrderDetailsSelectTabs
    },
    metaInfo() {
        return {
            title: `${this.orderid} | Order Details`
        }
    },
    mixins: [
        filtersMixin
    ],
    data: () => ({
        dataLoading: true,
        order: {},
        tickets: [],
        totalTickets: null,
        watchOrderid: true,
        ticketsNotification: null,
        filesLength: 0,
        showCreateTicketForm: false,
        selectedTab: {
            value: 'details',
            text: 'Details'
        }
    }),
    computed: {
        ...mapWriterFields([
            'writer_data.sw_id',
            'writer_data.accessstatus',
            'writer_data.status'
        ]),
        orderid() {
            return this.$route.params.id
        },
        getTabs() {
            return [
                {
                    value: 'details',
                    icon: List,
                    text: 'Details',
                    counter: false
                },
                {
                    value: 'order_files',
                    icon: PaperClip,
                    text: 'Files',
                    counter: this.order.counters?.files_main_count,
                    counterUndread: this.order.counters?.unread_files_main
                },
                {
                    value: 'order_additional_files',
                    icon: PaperClip,
                    text: 'Additional Files',
                    counter: this.order.counters?.files_add_count,
                    counterUndread: this.order.counters?.unread_files_add_count
                },
                {
                    value: 'order_messages',
                    icon: Mail,
                    text: 'Messages',
                    counter: this.order.counters?.messages_count,
                    counterUndread: this.order.counters?.unread_messages_count
                },
                {
                    value: 'order_support',
                    icon: Support,
                    text: 'Help Center',
                    counter: this.order.tickets?.length,
                    counterUndread: this.order.counters?.unread_ticket_messages_count
                }
            ]
        },
        breadcrumbsSteps() {
            return [
                {
                    title: 'Home',
                    url: '/'
                },
                {
                    title: 'Dashboard',
                    url: '/cp/account'
                },
                {
                    title: 'My Orders',
                    url: '/cp/orders'
                },
                {
                    title: this.orderid,
                    url: ''
                }
            ]
        }
    },
    watch: {
        $route(to) {
            if (to.query.cancelScroll) {
                return
            }
            this.$nextTick(() => {
                this.scrollToTabs(to.hash)
            })
        },
        selectedTab: {
            handler() {
                this.redirectToTab(this.selectedTab.value)
            },
            deep: true
        }
    },
    async mounted() {
        window.scrollTo(0, 0)
        if (!this.$route.query.cancelScroll) {
            setTimeout(() => {
                this.scrollToTabs(this.$route.hash)
            }, 500)
        }
    },
    created() {
        eventBus.$on('getTotalList', () => {
            this.getCounterSupportTickets()
        })
    },
    async activated() {
        try {
            this.$socket.emit('order:subscribe', this.$route.params.id)
        } catch (error) {
            console.error(error)
            eventBus.$emit('showSnackBar', error, 'error')
        }
        await this.getOrderData()
        if (!this.$route.query.cancelScroll) {
            this.scrollToTabs(this.$route.hash)
        }
    },
    deactivated() {
        this.$destroy()
    },
    beforeDestroy() {
        this.$socket.emit('order:unsubscribe', this.order.orderid)
    },
    methods: {
        redirectToTab(selectTab) {
            this.$router.push({ name: selectTab, a: 'a' })
        },
        scrollToTabs(hash) {
            if (!hash) { return }
            const ScrollTo = scroller();
            const element = document.querySelector('.orders-details-tabs')
            ScrollTo(element, {
                duration: 800,
                offset: -60
            });
        },
        markAsReadCounter(type) {
            console.log(type);
        },
        async getOrderData() {
            this.dataLoading = true
            await fetchOrder(this.orderid)
                .then((response) => {
                    this.order = response
                    this.order.showTools = true
                })
                .catch((error) => {
                    eventBus.$emit('showSnackBar', error, 'error')
                })
                .finally(() => {
                    this.dataLoading = false
                })
        }
    }
}
</script>

<style lang="scss">
.order .tab-wrapper .tab {
    display: inline;
}

.order-details__heading {
    padding: 65px 0 20px;
    background: #fafafa;

    .breadcrumbs-item a{
        font-size: 18px;
        color: $font-color-main;
    }
    .mdi-chevron-right {
        color: $font-color-main;
    }

    .orders-title h1 {
        font-size: 56px;
        @media all and (max-width: 576px){
            font-size: 24px;
        }
    }
}

.help-center {
    .tickets {
        padding: 0;
        margin-top: 30px;
    }

    .orders-details-tabs__support-tickets {
        padding: 30px 25px 10px;
        padding-top: 0;
        @media all and (max-width: 576px){
            padding: 0 15px 10px;
        }
    }

    .support-form {
        margin: 0;
        box-shadow: none;
    }
}

.order-tab-counter {
    position: relative;
    display: inline-flex;
    align-items: baseline;
    margin-left: 5px;
    padding: 7px 10px;
    font-size: 16px;
    font-weight: 600;
    color: #1161E0;
    background: rgba(17, 97, 224, 0.1)
}

@media screen and (max-width:768px){
    .orders-details-tabs__tab-links-text {
        display: none;
    }

    .order-tab-counter {
        font-size: 12px;
        padding: 5px 7px;
    }
}

.create-support-ticket {
    transform: translateY(-150%);
    animation: ani 1s forwards;
}

@keyframes ani {
    0% {transform: translateY(-10%);}
    100% {transform: translateY(0);}
}

</style>
