<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="12"
            cy="21"
            r="2"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M5 10C5 7.33333 5.875 2 12.4375 2C17.25 2 19 6.63158 19 10"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M2 13.4977C2 11.566 3.56596 10 5.49767 10V10C5.7751 10 6 10.2249 6 10.5023V17.9487C6 17.977 5.97704 18 5.94872 18H4.32453C3.04073 18 2 16.9593 2 15.6755V13.4977Z"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M22 14.3721C22 11.9575 20.0425 10 17.6279 10V10C17.2811 10 17 10.2811 17 10.6279V17.9359C17 17.9713 17.0287 18 17.0641 18H19.6755C20.9593 18 22 16.9593 22 15.6755V14.3721Z"
            stroke="#1161E0"
            stroke-width="1.8"
        />
        <path
            d="M19 18C19 20.1909 16.7273 22.3818 14 21.9436"
            stroke="#1161E0"
            stroke-width="1.8"
        />
    </svg>
</template>

<script>
export default {
    name: 'Support'
}
</script>
<style scoped lang='scss'>
svg {
    margin-right: 10px;
}
svg path {
    stroke: $main-color;
}
</style>
