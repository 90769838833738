<template>
    <nav>
        <ul class="orders-details-tabs__tab-links">
            <li
                v-for="item in items"
                :key="item.value"
                class="button transparent-btn"
                :class="{'active-tab-link': $route.name === item.value}"
                @click.prevent="redirectToTab(item.value)"
            >
                <component :is="item.icon" />
                <span>
                    {{ item.text }}
                </span>
                <span
                    v-if="item.counter !== false"
                    class="order-tab-counter"
                >
                    {{ item.counter }}
                    <span
                        v-if="item.counterUndread > 0"
                        class="bubble--unread bubble--unread-small"
                    >
                        {{ item.counterUndread }}
                    </span>
                </span>
            </li>
        </ul>
    </nav>
</template>

<script>

export default {
    name: 'OrderDetailsTabs',
    props: {
        items: {
            type: Array
        }
    },
    methods: {
        redirectToTab(value) {
            this.$emit('redirectToTab', value)
        }
    }
}
</script>
