<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 6H21"
            stroke="#1161E0"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8 12H21"
            stroke="#1161E0"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8 18H21"
            stroke="#1161E0"
            stroke-width="1.8"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3 6H3.01"
            stroke="#1161E0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3 12H3.01"
            stroke="#1161E0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3 18H3.01"
            stroke="#1161E0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'List'
}
</script>
<style scoped lang='scss'>
svg {
    margin-right: 10px;
}
svg path {
    stroke: $main-color;
}
</style>
