import { render, staticRenderFns } from "./PaperClip.vue?vue&type=template&id=7cb1145e&scoped=true&"
import script from "./PaperClip.vue?vue&type=script&lang=js&"
export * from "./PaperClip.vue?vue&type=script&lang=js&"
import style0 from "./PaperClip.vue?vue&type=style&index=0&id=7cb1145e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cb1145e",
  null
  
)

export default component.exports